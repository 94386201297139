import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { withCssUnit } from 'common/components/styleguide/utils';
import { Row, Column } from 'common/components/styleguide/grid';

import loadable from '@loadable/component';
const BannerVariant = loadable(() =>
  import(`common/blocks/banners/BannerVariant2`)
);
const Image = loadable(() => import('common/components/_base/Image'));

const S = {};

S.CustomBanner1 = styled(({ component, children, ...props }) => {
  const Component = component === 'div' && props.href ? 'a' : component;
  return <Component {...props}>{children}</Component>;
})`
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

S.Image = styled(Image)`
  display: block;
  width: 100%;
  height: auto;
`;

const CustomBanner1 = ({ data, className = '', imageSize = '330x204.jpg' }) => {
  if (!data) {
    return null;
  }
  const { url, photo, component = 'div' } = data;
  return (
    <S.CustomBanner1 component={component} href={url} className={className}>
      <S.Image
        {...photo}
        size={imageSize}
        srcSet={{}}
        lazyloadPlaceholder={
          <Image {...photo} lazyload={false} size={imageSize} />
        }
        alt="banner"
      />
    </S.CustomBanner1>
  );
};

S.CustomBanner1Top = styled(CustomBanner1)`
  margin-bottom: 0;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    margin-bottom: 20px;
  }
`;

const CustomBanners1 = ({ data }) => {
  if (_.isArray(data) && data.length >= 2) {
    return (
      <Row>
        <Column size={{ xs: 6, md: 12 }}>
          <S.CustomBanner1Top data={data[0]} />
        </Column>
        <Column size={{ xs: 6, md: 12 }}>
          <CustomBanner1 data={data[1]} />
        </Column>
      </Row>
    );
  }
  return null;
};

const BannerVariant1 = ({
  items = [],
  bannersAdditional1 = [],
  scrSet = {
    '460w': '530x219.jpg',
    '780w': '1060x438.jpg'
  },
  size = '1060x438.jpg'
}) => {
  let carouselBannersColumnSize = { xs: 12 };
  let displayAdditional = false;
  if (_.isArray(bannersAdditional1) && bannersAdditional1.length >= 2) {
    carouselBannersColumnSize = { xs: 12, md: 9 };
    displayAdditional = true;
  }
  return (
    <Row>
      {_.isArray(items) && items.length > 0 ? (
        <Column size={carouselBannersColumnSize}>
          <BannerVariant items={items} scrSet={scrSet} size={size} />
        </Column>
      ) : (
        ''
      )}
      {displayAdditional && (
        <Column size={{ xs: 12, md: 3 }}>
          <CustomBanners1 data={bannersAdditional1} />
        </Column>
      )}
    </Row>
  );
};

export default BannerVariant1;
